@import '../../../../styles/modules.scss';

.label {
  @include font($fw: $fw-bold, $fs: $fs-medium, $co: $co-black, $lh: rem(19px));
  display: flex;
  align-items: center;
}

.row {
  width: 100%;
  display: contents;
}

.selectDropDown {
  @include font(
    $fs: $fs-medium,
    $fw: $fw-book,
    $co: $co-greyText,
    $lh: rem(19px)
  );
  border: 1px solid $co-inputBorder;
  border-radius: 3px;
  background: $co-white url('../../../../assets/icon_arrow.svg') no-repeat right 22px center;
  -webkit-appearance: none;
  padding: 13px 20px 12px;
  @-moz-document url-prefix() {
    padding-left: 15px;
  }

  &:hover {
    cursor: pointer;
  }

  &:invalid {
    color: $co-greyText;
  }
  &.isTiny {
    @include font($fs: $fs-small, $lh: rem(14px));
    padding: 8px 10px 8px 10px;
    width: 100px;
    border: 1px solid $co-inputBorder;
    margin: -10px 0;
    text-align: right;
    background-position: right 6px center;
  }
}

.hasValue {
  color: $co-greyText;
}

.asterisk {
  color: $co-red;
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-bottom: 10px;
  margin-top: 8px;
}

