@use '../../../../styles/modules.scss' as *;

.root {
  width: 100%;
  background-color: white;
  [class*='inputText'],
  [class*='selectDropDown'] {
    width: 100%;
  }
  [class*='inputField_row'],
  [class*='customDonationInput_row'],
  [class*='selectField_row'] {
    display: contents;
  }

  [class*='errorText'] {
    grid-column: 2;
    // Con esto deshacemos el espacio entre filas
    margin-top: 0px;
  }

  [class*='buttonsGroup'] {
    grid-column: span 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  [class*='button_button'] {
    margin-top: 20px;
    grid-column: 2;
    display: inline-block;
    & + [class*='button_button'] {
    }
  }

  [class*='lineSeparator'] {
    grid-column: span 2;
  }

  &.isProfile,
  &.isSecurity {

    margin: auto;

    [class*='inputText'],
    [class*='selectDropDown'] {
      &[class*='hasError'] {
        background-image: none;
      }
    }

    [class*='button_button'] {
      margin-top: 0;
    }

    [class*='buttonsGroup'] {
      grid-column: span 2;
    }
    [class*='loading'] {
      grid-column: span 2;
    }
  }
}

.asterisc {
  @include font($co: $co-cadetblue);
}

.formTitle {
  font-family: 'Arvo', 'PT Sans', sans-serif ;
  font-weight: $fw-bold;
  background-color: $bg-global;
  padding: 25px 20px;
  width: 100%;
  min-height: 72px;
  @include font(
    $fs: $fs-big,
    $fw: $fw-bold,
    $lh: rem(20px),
    $co: $co-cadetblue
  );
}

.description {
  max-width: ele-formWidth(md);
  width: 100%;
  margin: 36px auto 0;

  @include font(
    $fs: $fs-medium,
    $fw: $fw-book,
    $lh: rem(20px),
    $co: $co-greyTextDark
  );
}

.form {
  max-width: ele-formWidth(md);
  width: 100%;
  margin: 36px auto 0;
  padding: 0 0 30px 0;
  display: grid;
  grid-template-columns: ele-labelWidth(xs) 1fr;
  row-gap: 10px;
  column-gap: 10px;

  .isProfile &,
  .isSecurity & {
    grid-template-columns: ele-labelWidth(md) 1fr;
  }

  .isSecurity & {
    display: block;
  }
}

.spaceRow_10 {
  margin-bottom: 10px;
  grid-column: span 2;
}

.spaceRow_20 {
  margin-bottom: 20px;
  grid-column: span 2;
}

@include media-breakpoint-up(lg) {
  .root {
    &.isProfile,
    &.isSecurity {

    }
  }

  .form {
    max-width: ele-formWidth(lg);
    grid-template-columns: ele-labelWidth(md) 1fr;

    .isProfile &,
    .isSecurity & {
      grid-template-columns: ele-labelWidth(lg) 1fr;
    }
  }

  .description {
    max-width: ele-formWidth(lg);
  }

}

.shortButton {
  margin-bottom: 20px;
  grid-column: span 2;
  display: flex;
  justify-content: center;
}

.dynamicNewInput {
  grid-column: span 2;
  row-gap: 10px;
  column-gap: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid $co-inputBorder;
  border-radius: 3px;
  background-color: #F7F7F7;

  .dynamicNewInputFields {
    display: grid;
    grid-column: span 2;
    row-gap: 10px;
    column-gap: 10px;
    [class*=textArea_row]{ display: block;}
    [class*=textArea_textarea]{ margin-top: 10px; }
  }

  .deleteElement {
    @include font(
            $fs: $fs-medium,
            $fw: $fw-medium,
            $lh: rem(20px),
            $co: $co-cadetblue
    );
    grid-column: span 2;
    cursor:pointer;
    padding: 10px 0;
    display: table;
    margin: 10px auto 0 auto;
  }
}
