@use '../../styles/modules.scss' as *;

.root {
  display: flex;
  height: 100vh;
  background-color: $co-white;

  button {
    margin-top: 62px;
  }
}

.titleWrapper {
  width: 400px;
  padding: 12% 20px 114px 30px;
  background-color: $co-cadetblue;
}

.title {
  @include font($fs: rem(34px), $lh: rem(44px), $fw: $fw-bold, $co: $co-white);
}

.wrapper {
  background-color: white;
  width: 100%;
  max-width: calc(336px + 40px);
  margin: 0 auto;
  padding: 124px 20px 0;
  form {
    border: 1px solid gray;
    border-radius: 19px;
    padding: 27px;
  }
}

.registerWrapper {
  background-color: white;
  width: 100%;
  max-width: calc(336px + 40px);
  margin: 0 auto;
  padding: 124px 20px 0;
}

.registerContent {
  vertical-align: middle;
  border: 1px solid gray;
  border-radius: 19px;
  padding: 27px;

  a {
    margin-top: 62px;
  }
}

.logoPobles {
  width: 250px;
  margin-bottom: 80px;
}

.logoFacilsure {
  width: 200px;
}

.mainText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(26px), $co: $co-black);
  margin-bottom: 0px;

  & + & {
    margin-top: 16px;
  }
}
.hasError {
  color: inherit;
  background-color: yellow;
}

.iconInput {
  position: absolute;
  left: 10px;
  z-index: 1;

  &.isMail {
    bottom: 14px;
  }

  &.isPassword {
    bottom: 10px;
    left: 12px;
  }
}

.inputWrapper {
  position: relative;
  margin-top: 61px;

  & + & {
    margin-top: 14px;
  }

  input {
    width: 100%;
    padding-left: 48px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $co-inputBorder;
  }

  [class*='errorText'] {
    display: none;
  }

  [class*='inputField_label'] {
    display: block;
    margin-bottom: 6px;
  }
}

.buttonWrapper {
  margin-top: 7px; // 7 xq el button ya tiene 14 i da los 21 de margin-top del primer input dels form
  margin-bottom: 10px;
}

.rememberLink {
  @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(17px), $co: $co-black);
  display: block;
  margin-top: 30px;
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-top: 38px;

  & + & {
    margin-top: 10px;
  }
}

@include media-breakpoint-up(md) {
  .title,
  .wrapper {
    padding-top: 12%;
  }
  .registerWrapper {
    padding-top: 20%;
  }
}

@include media-breakpoint-up(lg) {
  .mainText {
    @include font($fs: rem(18px), $lh: rem(28px));
  }

  .errorText {
    @include font($fs: rem(18px), $lh: rem(21px));
  }

  .rememberLink {
    @include font($fs: $fs-medium, $lh: rem(18px));
  }
}
