@import '../../../../styles/modules.scss';

.row {
  width: 100%;
  display: contents;
}

.label {
  @include font(
    $ff: $ff-primary,
    $fs: 16px,
    $fw: $fw-bold,
    $co: $co-black,
    $lh: 19px
  );
}

.textarea {
  @include font(
    $ff: $ff-primary,
    $fs: 16px,
    $fw: $fw-book,
    $co: $co-greyText,
    $lh: 19px
  );
  resize: none;
  width: 100%;
  border: 1px solid $co-inputBorder;
  border-radius: 3px;
  background-color: $co-white;
  padding: 13px 20px 12px;

  &.hasError {
    color: $co-red;
    border-color: $co-red;
    background: $co-white url('../../../../assets/icon_alert_red.svg') no-repeat
      right 10px top 14px;
  }
}

.errorText {
  @include font(
    $ff: $ff-primary,
    $fs: 16px,
    $fw: $fw-book,
    $lh: 19px,
    $co: $co-red
  );
  margin-bottom: 10px;
  margin-top: 8px;
}

.hasError {
  color: inherit;
}

.asterisk {
  color: $co-red;
}

.readOnly {
  overflow: hidden;
  @include font(
          $ff: $ff-primary,
          $fs: 16px,
          $fw: $fw-book,
          $co: $co-greyText,
          $lh: 19px
  );
  resize: none;
  width: 100%;
  min-height: 46px;
  border: 1px solid $co-inputBorder;
  border-radius: 3px;
  background-color: $co-white;
  padding: 13px 20px 12px;
  word-wrap: break-word;
}