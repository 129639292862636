@use '../../../../styles/modules' as *;

.root {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  &.isProfile {
    padding-top: 30px;
    border-top: 1px solid $co-inputBorder;

    [class*='button'] {
      margin-top: 0;
      & + [class*='button'] {
        margin-left: 20px;
      }
    }
  }

  &.isCentered {
    justify-content: space-evenly;

    [class*='button'] {
      margin-top: 0;
      & + [class*='button'] {
        margin-left: 20px;
      }
    }
  }

  &.isColumn {
    flex-direction: column;

    & + [class*='button'] {
      margin: 0;
      & + [class*='button'] {
        margin: 0;
      }
    }
  }

  [class*='formLayout'] & {
    margin-top: 20px;
  }
}


