@use '../../../../styles/modules.scss' as *;
@use '../buttonsPresets.module.scss' as *;

.button {
  @extend %button;

  &Positive {
    border-radius: 2.85px;
    background-color: $co-button;
    &:hover {
      @include hoverStyle($co-buttonHover);
    }
  }

  &Negative {
    color: $co-cadetblue;
    background-color: transparent;
    &:hover {
      @include hoverStyle($co-greyBgLighter);
    }
  }

  &.isReset {
    background: url('../../../../assets/icon_refresh.svg') no-repeat center;
    padding: 20px;
  }

  &.isDownload {
    background: url('../../../../assets/downloadCSV.svg') no-repeat center;
    padding: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  [class*='heading'] & {
    @include font(
      $fs: $fs-medium,
      $fw: $fw-book,
      $lh: rem(16px),
      $co: $co-cadetblue
    );
    display: inline-block;
  }

  &Outlined {
    border: 1px solid $co-inputBorder;
    border-radius: 2.85px;
    color: $co-cadetblue;
    background-color: transparent;
    &:hover {
      @include hoverStyle($co-greyBgLighter);
    }
  }

  &:disabled {
    border: 1px solid black;
    background-color: grey;
    color: white;
    padding: 15px;
    cursor: not-allowed;
  }
}

/**
  Button size styles
*/
.button {
  &.xsmall {
  }
  &.small {
    @include font($fs: $fs-small, $fw: $fw-book, $lh: rem(16px));
    padding: 10px 20px;
  }
  &.medium {
    padding: 14px 26px;
    &:disabled {
      border: 1px solid black;
      background-color: $co-cadetblueLight;
      color: $co-cadetblue;
      padding: 13px 32px;
      cursor: not-allowed;
    }
  }
  &.large {
  }
}

.icon {
  border: 0;
  background: 0;

  svg {
    height: 16px;
    width: 12px;
    fill: $co-greyText;
  }

  &:hover {
    svg {
      fill: $co-cadetblue;
    }
  }
}
