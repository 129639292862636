/* ========================================================================================= */
/* TOOLS - MIXINS */
/* ========================================================================================= */

// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

//* PLACEHOLDER *//

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

//* END PLACEHOLDER *//

// LETTER-SPACING FROM ADOBE XD TO EM //

@mixin letter-spacing($spacing) {
  letter-spacing: ($spacing / 1000) * 1em;
}

// END LETTER-SPACING FROM ADOBE XD TO EM //

// Define vertical, horizontal, or both position
@mixin center($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin font(
  $ff: false,
  $fs: false,
  $fw: false,
  $fst: false,
  $lh: false,
  $co: false,
  $ls: false,
  $lsC: false
) {
  @if $ff {
    font-family: $ff;
  }
  @if $fs {
    font-size: $fs;
  }
  @if $fw {
    font-weight: $fw;
  }
  @if $fst {
    font-style: $fst;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $co {
    color: $co;
  }
  @if $ls {
    letter-spacing: $ls;
  }
  @if $lsC {
    @include letter-spacing($ls);
  }
}

/* END FONT */

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(
    legacy-direction($direction),
    $color-stops
  );
  background: linear-gradient($direction, $color-stops);
}

/* Tests

.test-1 {
  @include linear-gradient(#31B7D7, #EDAC7D);
}

.test-2 {
  @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
}

.test-3 {
  @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
}
*/
