@import '../../../../styles/modules.scss';

.label {
  @include font($fw: $fw-bold, $fs: $fs-medium, $co: $co-black, $lh: rem(19px));
  display: flex;
  align-items: center;
}

.row {
  width: 100%;
  display: contents;
}

.selectDropDown {
  @include font(
    $fs: $fs-medium,
    $fw: $fw-book,
    $co: $co-greyText,
    $lh: rem(19px)
  );

  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:invalid {
    color: $co-greyText;
  }
  &.isTiny {
    @include font($fs: $fs-small, $lh: rem(14px));
    width: 100px;
    margin: -10px 0;
    text-align: right;
    background-position: right 6px center;
  }

  > div[class^='react-select__control'], div[class*=' react-select__control']{
    padding: 5px 8px;
    border-radius: 3px;
  }

  > div[class^='-placeholder'], div[class*=' -placeholder']{
    font-size: 11px!important;
    font-weight: $fw-book!important;
    color: $co-greyText!important;
    line-height: rem(19px)!important;
  }
}

.hasValue {
  color: $co-greyText;
}

.asterisk {
  color: $co-red;
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-bottom: 10px;
  margin-top: 8px;
}

