/* ========================================================================================= */
/* TAGS - TAGS */
/* ========================================================================================= */

html {
  font-size: 100%;
  font-family: $ff-primary;
  background-color: #f5f6f9;
}

a {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

strong {
  font-weight: $fw-bold;
}

select:disabled,
input:disabled {
  background-color: $co-greylighter;
  color: $co-greyText;

  &:hover {
    cursor: default;
  }
}

button:disabled {
  background-color: $co-greyText;

  &:hover {
    cursor: default;
    background-color: $co-greyText;
  }
}

input::placeholder {
  color: $co-greyText;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
