@use "../../../styles/modules.scss" as *;

.snackBar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $co-neutralBg;
  width: 100vw;
  text-align: center;
  padding: 12px 15px;
  display: flex;
  justify-content: center;
  z-index: $zi-modal - 1;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;

  &.isRich {
    text-align: left;
    span {
      font-weight: 700;
    }
    svg {
      height: 24px;
    }
  }

  svg {
    fill: $co-white;
    width: 20px;
  }

  &.isError {
    background-color: $co-errorBg;
  }

  &.isSuccess {
    background-color: $co-successBg;
  }

  &.isOpen {
    transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    transform: translateY(0);
    opacity: 1;
  }
}

.text {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(26px), $co: $co-white);

  svg + & {
    margin-left: 6px;
  }
}
