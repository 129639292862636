.modalContent {
  @include font($fs: rem(16px), $fw: $fw-book, $lh: rem(16px));
  .title {
    @include font(
      $fs: rem(18px),
      $fw: $fw-bold,
      $lh: rem(22px),
      $co: $co-black
    );
    display: block;
    background-color: transparent;
    text-align: center;
    svg {
      height: 18px;
      width: 29px;
      fill: $co-greyText;
      margin-right: 8px;
    }
  }
  .message {
    margin-top: 32px;
    &.centered {
      text-align: center;
    }
  }
  .actions {
    border-top: 1px solid $co-inputBorder;
    margin-top: 80px;
  }
}
