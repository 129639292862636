@import '../../../../styles/modules.scss';

.row {
  width: 100%;
}

.label {
  @include font($fs: $fs-medium, $fw: $fw-bold, $co: $co-black, $lh: rem(19px));
  display: flex;
  align-items: center;
}

.inputText {
  @include font(
          $fs: $fs-medium,
          $fw: $fw-book,
          $co: $co-greyText,
          $lh: rem(19px)
  );
  border: 1px solid $co-inputBorder;
  border-radius: 3px;
  background-color: $co-white;
  padding: 13px 20px 12px;

  &.hasError,
  &.hasLoginError {
    color: $co-red;
    border-color: $co-red;
    background: $co-white url('../../../../assets/icon_alert_red.svg') no-repeat
      right 3px center;
  }

  &.hasError {
    [class*='changeActions_filter'] & {
      background: white;
    }
  }

  [class*='statistics_root'] & {
    @include font($fs: $fs-small, $lh: rem(17px), $co: $co-greyText);
    border: 0;
    border-radius: 0;
    padding: 13px 10px 11px;
    min-width: 180px;
  }
}

.asterisk {
  color: $co-red;
}

.errorText {
  @include font($fs: $fs-medium, $fw: $fw-book, $lh: rem(19px), $co: $co-red);
  margin-bottom: 10px;
  margin-top: 8px;
}

.hasError {
  color: inherit;
}
