@use '../../../../styles/modules.scss' as *;

.wrapper {
  width: 100%;
  grid-column: span 2;
  vertical-align: top;
  padding: 10px 0;
}

.title {
  @include font(
    $ff: 'PT Sans',
    $fs: $fs-bigger,
    $fw: $fw-bold,
    $lh: rem(26px),
    $co: $co-greyTextDark
  );
  font-family: 'PT Sans', sans-serif ;
  font-weight: bold;
  background-color: $bg-global;
  margin-bottom: 10px;

  span {
    font-weight: $fw-book;
  }
}
