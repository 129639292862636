@use '../../../../styles/modules.scss' as *;

.root {
  //padding-top: ele-header(xs);
  display: flex;
  flex-wrap: wrap;
}

.header {
  position: sticky;
  top: 0;
  z-index: $zi-menu;
  width: 100%;
  background-color: $co-white;
  padding: 15px 16px 15px 23px;
  border-bottom: 2px solid $co-greylighter;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoLink {
  display: block;
}
.logoImg {
  width: 104px;
}

.navigation {
  padding: 18px 12px 20px;
  max-width: $ele-mainMenuWidth;
  height: calc(100vh - #{ele-header(xs)});
  background-color: $co-white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ele-header(xs);
  left: 0;
  z-index: $zi-menu;
  transition: max-width 0.2s ease-out 0s;

  &.isOpen {
    width: auto;
    max-width: 240px;
    box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.16);
    transition-delay: 0.2s;
  }
}

.mainMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuItem {
  & + & {
    margin-top: 12px;
  }

  &.isNotification {
    margin-top: auto;
  }
}

.menuLink {
  padding: 10px 7px;
  line-height: 0;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    .menuText {
      color: $co-cadetblue;
    }

    .menuIcon {
      fill: $co-cadetblue;
      color: $co-cadetblue;
    }
  }
  &.isCurrentPage {
    .menuText {
      color: $co-cadetblue;
    }

    .menuIcon {
      fill: $co-cadetblue;
      color: $co-cadetblue;
    }
  }
}
.menuLinkDisabled {
  padding: 10px 7px;
  line-height: 0;
  display: flex;
  align-items: center;
  opacity: 0.4;
  pointer-events: none!important;
  cursor: not-allowed!important;

  > .iconWrapper svg {
    opacity: 0.5;
  }
}
.menuItem:last-child .menuLink {
  font-size: 20px;
}
.iconWrapper {
  width: 34px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.icon {
  width: 34px;
}

.menuText {
  @include font(
    $fs: $fs-small,
    $fw: $fw-book,
    $lh: rem(14px),
    $co: $co-greyText
  );
  text-decoration: none;
  visibility: hidden;
  opacity: 0;
  width: 0;
  white-space: nowrap;
  transition: width 0.2s ease-out 0s, opacity 0.1s ease-out 0s;

  .isOpen & {
    visibility: visible;
    opacity: 1;
    width: 100%;
    margin-left: 10px;
    transition: width 0.2s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
  }
}

.menuIcon {
  fill: $co-greyText;
  color: $co-greyText;
}
.menuIconPerfil {
  fill: $co-cadetblue;
  color: $co-cadetblue;
}
.mainContent {
  min-height: calc(100vh - #{ele-header(xs)});
  position: relative;
  padding: 20px 25px 38px;
  margin-left: $ele-mainMenuWidth;
  width: calc(100% - #{$ele-mainMenuWidth});
  transition: width 0.3s ease-out, margin 0.3s ease-out;
}

.headerMenu {
  display: flex;
  align-items: center;
  min-width: 120px;
}

.headerMenuItem {
  & + & {
    margin-left: 4px;
  }

  &.isLogo {
    transform: translateX(-50%);
    margin-right: auto;
  }
}

.headerMenuUserFullname {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include font(
    $fs: $fs-small,
    $fw: $fw-book,
    $lh: rem(16px),
    $co: $co-greyTextDark
  );
  border: 0;
  background: transparent;
  padding: 4px 10px;
}

.headerMenuButton,
.headerMenuLink {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include font(
    $fs: $fs-small,
    $fw: $fw-book,
    $lh: rem(16px),
    $co: $co-greyText
  );
  border: 0;
  background: transparent;
  padding: 4px 10px;

  &:hover {
    cursor: pointer;
    color: $co-cadetblue;
  }
}

.headerUserIcon {
  width: 14px;
  margin-right: 6px;
  fill: $co-greyTextDark;
  color: $co-greyTextDark;
}

.headerMenuIcon {
  margin-top: rem(2.2px);
  width: 14px;
  margin-right: 4px;
  fill: $co-greyText;

  .headerMenuButton:hover &,
  .headerMenuLink:hover & {
    fill: $co-cadetblue;
  }
}

.burger {
  background-color: transparent;
  border: 0;
  padding: 16px 14px 14px;
  margin-bottom: 12px;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.burgerBox {
  width: 20px;
  height: 16px;
  display: flex;
  justify-content: center;
  position: relative;
}

.burgerInner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transform: translateY(-50%);
  bottom: 50%;

  &,
  &:after,
  &:before {
    width: 20px;
    height: 2px;
    background-color: $co-greyText;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &:after,
  &:before {
    content: '';
    display: block;
  }

  &:after {
    bottom: -6px;
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &:before {
    top: -6px;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }

  .isOpen & {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &:after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    }
  }
}

.alarmAnimation {
  -webkit-animation: ring 4s 0.7s ease-in-out;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out;
  transform-origin: 50% 4px;
  position: relative;

  .alarmOval {
    position: absolute;
    left: 19px;
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

@include media-breakpoint-up(lg) {
  .header {
    padding: 17px 30px 17px 23px;
  }

  .logoImg {
    width: 150px;
  }

  .navigation {
    height: calc(100vh - #{ele-header(md)});
    top: ele-header(md);
    left: 0;
    padding: 35px 12px 20px;
    position: sticky;
  }

  .mainContent {
    padding: 35px 40px;
    min-height: calc(100vh - #{ele-header(md)});
    margin-left: 0;
    flex: 1;
  }

  .menuText {
    @include font($fs: $fs-medium, $lh: rem(16px));
    padding-top: 1px;
  }
}


.paper {
  box-sizing: border-box;
  height: 2237px;
  width: 1302px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
}

.indented {
  margin-left: 20px;
  transition: width 0.2s ease-out 0s, opacity 0.1s ease-out 0s;
}

.submenuItem {

  a {
    padding: 7px 7px!important;
  }

  .iconWrapper {
    width: 14px!important;
  }

}
